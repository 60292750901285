import { Component } from '@angular/core';
import { IStatusPanelParams } from 'ag-grid-community';

@Component({
  selector: 'status-component',
  template: `<div class="valueCount">
  <span style="font-weight: bold; margin-left: 5px;"> Invalid Values : </span> <span style="color: black;">{{params.context.componentParent.invalidCount}}</span>  
  </div>`,
  styles: ['.valueCount { margin-left: 4px;margin-right: 4px;padding-top: 8px;padding-bottom: 8px; }'],
})
export class InvalidCountComponent {
  public params: IStatusPanelParams;

  agInit(params: IStatusPanelParams): void {
    this.params = params;
  }
}