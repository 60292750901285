import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { CustomMessageService } from './custom.message.service';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { AppConstants } from '../shared/appconstants';
import { GridApi } from 'ag-grid-community';

@Injectable({
  providedIn: 'root',
})
export class CommonService { 
 
  constructor(private apiService: ApiService, public customMessageService: CustomMessageService) {} 

   downloadExcelFile(agGridApi?: GridApi, selected? : boolean, isExcel_CODE : boolean = false){  
    this.apiService.downloadExcelWithMipLabels(agGridApi, selected, isExcel_CODE).subscribe((response: any) => {
      if (response) { 
        const blob = new Blob([response], { type: 'application/ms-excel' });
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = window.URL.createObjectURL(blob);     
        let fileName = "PCM_" + this.customMessageService.getDateString(new Date()) + ".xlsx";
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);
        }, 100);
      }
    });
  }

  generateExcel(): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');
    const headers = AppConstants.ImportFileHeaders;
    worksheet.addRow(headers);
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `PCM_${this.getDateTime()}.xlsx`);
    });
  }

   getDateTime() : string {
    let now : any    = new Date(); 
    let year : any   = now.getFullYear();
    let month : any  = now.getMonth()+1; 
    let day     = now.getDate();
    let hour    = now.getHours();
    let minute  = now.getMinutes();
    let second  = now.getSeconds(); 
    if(month.toString().length == 1) {
         month = '0'+month;
    }
    if(day.toString().length == 1) {
         day = '0'+day;
    }   
    if(hour.toString().length == 1) {
         hour = '0'+hour;
    }
    if(minute.toString().length == 1) {
         minute = '0'+minute;
    }
    if(second.toString().length == 1) {
         second = '0'+second;
    }   
    let dateTime = year+'_'+month+'_'+day+' '+hour+'_'+minute+'_'+second;  
     return dateTime;
}

  
  static groupBy(array: any[] , f: any )
  {
    var groups: any = {};
    array.forEach( function( o )
    {
      var group: any = JSON.stringify( f(o) );
      groups[group] = groups[group] || [];
      groups[group].push( o );  
    });
    return Object.keys(groups).map( function( group )
    {
      return groups[group]; 
    })
  }
}