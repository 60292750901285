import { Component, OnInit } from '@angular/core';
import { CellClassParams, GetContextMenuItemsParams, MenuItemDef, RowClassRules } from 'ag-grid-community';
import { StaticFields } from 'src/app/shared/staticFields';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { CustomMessageService } from 'src/app/services/custom.message.service';
import { Crop } from 'src/app/models/crop';
import { CommonService } from 'src/app/services/common.service';
import { AppConstants, ImportFileHeader } from 'src/app/shared/appconstants';
import { Germplasm } from 'src/app/models/germplasm';
import { InvalidCountComponent } from 'src/app/shared/invalidCountComponent';
import { RadioButtonClickEvent } from 'primeng/radiobutton';


@Component({
  selector: 'app-import-file',
  templateUrl: './import-file.component.html',
  styleUrls: ['./import-file.component.scss']
})
export class ImportFileComponent implements OnInit {  
  stages: string[] = [];
  metaData: string[] = [];
  programs: string [] = [];
  gePrograms: string[] = [];
  fileName: any;
  importedExcelData: any[] = [];
  gridImportData: any[] = [];
  sheets: any[] = [];
  excelData: any[] = [];
  showConfirmSheet: boolean = false;
  showImportGrid: boolean = false;
  importColumnDefs: any[] = []; 
  headerCols: any[] = [];
  openImportedData: boolean = false;
  dataColDef: any [] = [];
  valDataColDef: any = [];
  dataToSubmit: any[] = [];
  selectedGeIdsData: any[] = [];
  onlyDefaultName = true;
  selectedCrop: any;  
  selectedGECrop: any;
  selectedYear : any;
  idList: string[] = [];
  displayGrid: boolean = false;
  searchBy: string = "IMPORT EXCEL";
  searchByGeIdName: string = "GE ID";
  openImportByGes : boolean = false;
  invalidIds: string[] = [];
  items: MenuItem[] | undefined;
  geIdColDef : any = [
    { field: 'geId', headerName: 'GE ID', minWidth: 50, width: 100},
    { field: 'geName', headerName: 'GE Name',minWidth: 50, width: 115},
    { field: 'existingVals', headerName: 'Existing Program:PStage',minWidth: 50, width: 185},
    { field: 'name', headerName: 'Program', editable: true, minWidth: 30, width: 110, 
      cellEditorSelector: () => {
        return {
          component: 'agRichSelectCellEditor',
          params: {
            values: this.gePrograms,
            searchType: 'matchAny',
            filterList: true,
            highlightMatch: true,
            multiSelect: false
          }
        }
      },
      cellStyle: cellStyle,
    },
    { field: 'stage', headerName: 'PStage', cellEditor: 'agSelectCellEditor', editable: true, minWidth: 30, width: 100,
      cellEditorParams: { values: this.stages}, cellStyle: stageCellStyle, },
    { field: 'comments', headerName: 'Program Comment',minWidth: 50, editable: true},
    { field: 'metadata', headerName: 'Metadata',minWidth: 50,editable: true},
    { field: 'customMetaData', headerName: 'Custom Metadata',minWidth: 50,editable: true}
  ]
  defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };
  geGridOptions : any = {
    context: {
      componentParent: this,
    },
    statusBar: {
      statusPanels: [
        {
          statusPanel: 'agTotalRowCountComponent',
          align: 'right',
        }
      ]
    }
  };

  gridOptions : any = {
    popupParent: document.querySelector('body'),
    context: {
      componentParent: this,
    },
    statusBar: {
      statusPanels: [
        {
          statusPanel: 'agTotalRowCountComponent',
          align: 'right',
        },
        {
          statusPanel: InvalidCountComponent,
          //key: 'valueCompKey',
          align: 'left',
        }
      ],
    }
  };
  selectedGeIdsDataCopy: any;

  getRowClass (params : any) : any {
    if (params.data.rowClass  === "invalid") {
      return  {
        'background-color': 'orange'
      }
    } else  if (params.data.rowClass  === "default") {
        return {
          'background-color': 'transparent'
        }
    }
  };
  public rowClassRules: RowClassRules = {  
    'invalid': (params) => {
      return params.data['rowClass'];;
    },
  };

  get crops(): Crop [] {
    return StaticFields.crops?.filter(ele => ele.isActive);
  }

  get years(): any [] {
    return StaticFields.years;
  }

  get mappedColCount(): number {
    return this.importColumnDefs.filter((c: any) => c['mappedCol']).length;
  }

  get tooltipText(): string {
     const mappedCols = this.importColumnDefs.map((c: any) => c['mappedCol']);  
     if (mappedCols.findIndex(c => c === ImportFileHeader.geId || c === ImportFileHeader.geName) < 0 ||  mappedCols.findIndex(c => c === ImportFileHeader.program) < 0 || mappedCols.findIndex(c => c === ImportFileHeader.year) < 0 || mappedCols.findIndex(c => c === ImportFileHeader.stage) < 0) {
        return "Please map GE Id/Name, Program, Year, PStage ";
     }   
    return "All required columns mapped.";
  }

  get submitDisabled(): boolean{
    return (this.dataToSubmit.filter(p => p.IsValid == false).length > 0 || this.dataToSubmit.length === 0);
  }

  get idListText(): string {
    return this.idList.join("\r\n");
  }

  set idListText(value) {
    this.idList = value.split(/\r?\n/);
    if (this.idList.length < 2)
      this.idList = value.split(",");
    this.idList = this.idList.filter((v, i, a) => a.indexOf(v) === i);
    this.idList = this.idList.map(s => s.trim()).filter(s => s);
  }

  get invalidIdListText(): string {
    return this.invalidIds.join("\r\n");
  }

  get saveEnabledForGes() : boolean {
    return JSON.stringify(this.selectedGeIdsDataCopy) != JSON.stringify(this.selectedGeIdsData);
  }

  get invalidCount() : number {
    return this.dataToSubmit ? this.dataToSubmit?.filter((ele: any) => ele['classProp'] == "invalid").length : 0;
  }


  constructor(private confirmService: ConfirmationService, private apiService : ApiService, public loader: LoadingService, public customMessageService: CustomMessageService, public commonService : CommonService) { }

  ngOnInit(): void {
    this.items = [    
      {
          label: 'By GE Id/Name',
          icon: 'pi pi-clipboard',
          command: () => this.openGeDialog()
      },
      {
          label: 'From file',
          icon: 'pi pi-folder-open',
          disabled: this.selectedCrop,
          command: () => this.importFile('importFile')
      }    
] ;
    if (StaticFields.appUserId) {
      this.selectedGECrop = this.selectedCrop = this.crops?.find(ele => ele.Name == StaticFields.precodePreference?.crop);
      this.getMasterData(true)
    } else {
      const interval = setInterval(() => {
        if (StaticFields.appUserId) {
          this.selectedGECrop = this.selectedCrop = this.crops?.find(ele => ele.Name == StaticFields.precodePreference?.crop);
          this.getMasterData(true)
          clearInterval(interval);
        }
      }, 600);
    }
  }

  importFile(input: string) {     
    if(this.selectedCrop == undefined) {
      this.customMessageService.showMessage({'severity': 'error', summary: 'Select Crop', detail: "Please Select Crop."});  
      return;
    }
    this.openFile(input);
    this.getMasterData(false);    
    this.getMetaData();
    this.getPrograms(false);  
  }

  openFile(input: string) {
    document.getElementById(input)?.click();
  }

  handle(event: any) {
    this.loader.setMessage('Loading...'); 
    setTimeout(() => {
      const file = event.target.files[0];
        if (typeof Worker !== 'undefined') {
          const worker = new Worker(new URL('../.././app.worker', import.meta.url), { type: 'module' });  
          worker.onmessage = ({ data }) => {
            this.fillGridWithData(data);
          };
          worker.onerror = errorEvent => {
            const formData = new FormData();
            formData.append('file', file);
            this.apiService.postData("GetDecryptedExcelFileData", formData, false).subscribe({
              next: (response: any) => {
                if(response.httpStatusCode == 200) {
                 this.fillGridWithData(response.data[0]);
                }
              }           
            });
          };
          worker.postMessage(file);
          this.fileName = file.name;
          event.target.value = "";
        } else {
          console.log("Worker not supported.");
        }
    }, 600);
  }

  fillGridWithData(data: any) {
    if(data.data?.length == 0){
      this.loader.clearMessage();
      this.customMessageService.showMessage({'severity': 'error', summary: 'No Data', detail: "No Data in Imported File."}); 
      return; 
    }
    const sheets = Object.getOwnPropertyNames(data);
    if (sheets.length === 1) {
      this.importedExcelData =  data[<any>(sheets[0])];
      this.gridImportData = this.importedExcelData.slice(0, 15);
      this.fillGrid();
    } else if (sheets.length > 1) {
      this.loader.clearMessage();
      this.sheets = [];
      setTimeout(() => {
        sheets.forEach(s => {
          this.sheets.push({ name: s });
        });
        this.excelData = data;
        this.showConfirmSheet = true;
      }, 10);
    } else {
      this.loader.clearMessage();
      this.customMessageService.showMessage({'severity': 'error', summary: 'Invalid File', detail: "No sheet found in imported file."});  
    }
  }

  onSheetChange(args: any) {
    this.importedExcelData = this.excelData[args.value.name];
    this.gridImportData = this.importedExcelData.slice(0, 15);
    this.fillGrid();
    this.showConfirmSheet = false;
  }

  addHeaderCol(k: string, mappedColumn: string) {
    let duplicateColFound = false;
    if (this.importColumnDefs.findIndex((c: { mappedCol: string; }) => c.mappedCol === mappedColumn) > -1)
      duplicateColFound = true;
    this.importColumnDefs.push({ header: k, mappedCol: mappedColumn});
    return duplicateColFound;
  }

  addValidatedHeaderCol(header: string, field: string, memberData : boolean) {
    if (this.valDataColDef.findIndex((ele : any) => ele.headerName === header) < 0) {   
      let headerObject = {'headerName' : header, 'field' : field, tooltipField: field + 'invalidReason', valueGetter: (params : any) => {return params?.data[field];}, minWidth: 70, width: 130, resizable : true};
      this.valDataColDef.push(headerObject);
    }
  }

  fillGrid() {
    this.showImportGrid = false;  
    this.importColumnDefs.splice(0); 
    this.headerCols =  AppConstants.ImportFileHeaders;
    const header = JSON.parse(StaticFields.homeScreenData.mappingModel);
    const geIdHeaders = header.geId?.toUpperCase().split(',');
    const geNameHeaders = header.geName?.toUpperCase().split(',');
    const nameHeaders = header.programName?.toUpperCase().split(',');
    const yearHeaders = header.year?.toUpperCase().split(',');
    const stageHeaders = header.stage?.toUpperCase().split(',');
    const metaDataHeaders = header.metaData?.toUpperCase().split(',');
    const customMetaDataHeaders = header.customMetaData?.toUpperCase().split(',');
    const ccommentHeaders = header.comments?.toUpperCase().split(',');
  
    try {
      let duplicateColFound = false;
      Object.keys(this.gridImportData[0]).forEach(k => { 
        if (this.headerCols.findIndex(c => c.toUpperCase().trim() === k.toUpperCase().trim()) > -1) {
          duplicateColFound = this.addHeaderCol(k, this.headerCols.find(c => c.toUpperCase().trim() === k.toUpperCase().trim()));
        } else {
          if (geIdHeaders?.findIndex((c: string) => c.trim() === k.toUpperCase().trim()) > -1)
            duplicateColFound = this.addHeaderCol(k, ImportFileHeader.geId);
          else if (geNameHeaders?.findIndex((c: string) => c.trim() === k.toUpperCase().trim()) > -1)
            duplicateColFound = this.addHeaderCol(k, ImportFileHeader.geName);
          else if (nameHeaders?.findIndex((c: string) => c.trim() === k.toUpperCase().trim()) > -1)
            duplicateColFound = this.addHeaderCol(k, ImportFileHeader.program);
          else if (yearHeaders?.findIndex((c: string) => c.trim() === k.toUpperCase().trim()) > -1)
            duplicateColFound = this.addHeaderCol(k, ImportFileHeader.year);
          else if (stageHeaders?.findIndex((c: string) => c.trim() === k.toUpperCase().trim()) > -1)
            duplicateColFound = this.addHeaderCol(k, ImportFileHeader.stage);
          else if (metaDataHeaders?.findIndex((c: string) => c.trim() === k.toUpperCase().trim()) > -1)
            duplicateColFound = this.addHeaderCol(k, ImportFileHeader.metaData);
          else if (customMetaDataHeaders?.findIndex((c: string) => c.trim() === k.toUpperCase().trim()) > -1)
            duplicateColFound = this.addHeaderCol(k, ImportFileHeader.customMetaData);
          else if (ccommentHeaders?.findIndex((c: string) => c.trim() === k.toUpperCase().trim()) > -1)
            duplicateColFound = this.addHeaderCol(k, ImportFileHeader.programComment);
          else
            this.importColumnDefs.push({ header: k});
        }
      });
      this.loader.clearMessage();
      if (duplicateColFound) {
        this.customMessageService.showMessage({'severity': 'error', summary: 'Duplicate Columns', detail: "Sheet contains duplicate columns."}); 
        return;
      }
      this.showImportGrid = this.openImportedData = true;
    }
    catch (e: any) {
      this.loader.clearMessage();
      this.customMessageService.showMessage({'severity': 'error', summary: 'Load Error', detail: "Excel Load error: " + e.message}); 
    }
  }

  onHeaderSelectionChange(target: any, col: any) {
    const value = target.value.split(":")[1].trim();
    if (value) {
        if (this.importColumnDefs.findIndex((c: { mappedCol: any; }) => c.mappedCol === value) > -1) {
          this.customMessageService.showMessage({'severity': 'error', summary: 'Duplicate Columns', detail:  value + " has already been mapped."}); 
          target.selectedIndex = this.headerCols.findIndex(h => h === col['mappedCol']);
        } else {
          col['mappedCol'] = value;
          col['customCol'] = '';
        }
    } else
      col['customCol'] = col['mappedCol'] = '';
  }

  markInvalid(invalidItems: any, classProp: string, prop: string, reason: string, isBlank?: boolean, isNotFound?: boolean) {
    invalidItems.forEach((item: any) => {
      if(((prop == "GeName" && !item.GeId) && !(isBlank && isNotFound)) || (prop == "GeName" && item.GeId)) {
        item['IsValid'] = true;
      } else {
        item['IsValid'] = false;
        item['classProp'] = isBlank ? "blank" : isNotFound ? "notfound" : "invalid";
      }
     
      const foundProp = this.valDataColDef.find((e: any) => e.field === prop);
      if (foundProp) {
        foundProp['cellStyle'] = (params: { data: { [x: string]: any; }; }) => {
          const className = params.data['classProp'];
          return { backgroundColor:  className === "invalid" || className === "notfound" ? 'orange' : className === "default" ? 'transparent' : className === "blank" ? "yellow" : 'transparent' };
        }   
        item[prop + "invalidReason"] = reason ? reason : "";
      }      
    });
  } 

  onValidate() {
    if (this.tooltipText !== "All required columns mapped.") {    
      this.customMessageService.showMessage({'severity': 'error', summary: 'Unmapped Columns', detail:  this.tooltipText}); 
      return;
    }
    this.displayGrid = false;
    this.loader.setMessage('Loading...');
    this.valDataColDef.splice(0);
    this.dataToSubmit.splice(0);
    this.dataColDef = this.importColumnDefs.filter((c: { [x: string]: any; }) => c['mappedCol'] );
    let col = this.dataColDef.find(d => d.mappedCol === ImportFileHeader.geId);
    if (!(col && this.importedExcelData[0][col.header]))
      col = this.dataColDef.find(d => d.mappedCol === ImportFileHeader.geName);
    let geList = this.importedExcelData.map(p => p[col.header]);
    this.apiService.postData('GetGEInfo', {cropName: this.selectedCrop.Name, geList: geList}, false).subscribe((result : any) => {
      if (result.httpStatusCode == 200) {
        let germplasms: Germplasm[] = result.data;  
        let existingGeIds: number[] = germplasms.map(p => p.geId);
        let existingGeNames: string[] = germplasms.map(p => p.name);    
        this.importedExcelData.forEach(row => {
          let item: any = {};
          col = this.dataColDef.find(d => d.mappedCol === ImportFileHeader.geId);
          if (!(col && this.importedExcelData[0][col.header]))
            col = this.dataColDef.find(d => d.mappedCol === ImportFileHeader.geName);
          if (col.mappedCol === ImportFileHeader.geId) {
            item.GeId = row[col.header];
            this.addValidatedHeaderCol(ImportFileHeader.geId, "GeId", false);              
            let geIdExists = existingGeIds.includes(Number(item.GeId)); 
            if (item.GeId == null || !geIdExists)
            this.markInvalid([item],"geId_class", "GeId", item.GeId == null ?  "Required" : "Ge Id Not Found In " + this.selectedCrop.Name, item.GeId == null ? true : false, !geIdExists);
          } else if (col.mappedCol === ImportFileHeader.geName){
            item.GeName = row[col.header];
            item.GeId =  item.GeName ? germplasms.find(p => p.name == item.GeName)?.geId : item.GeId;
            this.addValidatedHeaderCol(ImportFileHeader.geName, "GeName", false);   
            let geNameExists = existingGeNames.includes(item.GeName); 
            if (item.GeName == null || !geNameExists)
              this.markInvalid([item],"geName_class", "GeName", item.GeName == null ?  "Required" : "Ge Name Not Found In " + this.selectedCrop.Name, item.GeName == null ? true : false, !geNameExists);
          }       
          col = this.dataColDef.find(d => d.mappedCol === ImportFileHeader.program);
          if (col) {
            item.Name = row[col.header];
            this.addValidatedHeaderCol(ImportFileHeader.program, "Name", false);         
            let programExists = this.programs.includes(item.Name); 
            if (item.Name == null || !programExists)
              this.markInvalid([item],"program_class", "Name", item.Name == null ?  "Required" : "Program Not Found In " + this.selectedCrop.Name, item.Name == null ? true : false, !programExists);
          }      
          item.Crop = this.selectedCrop.Name;       
          col = this.dataColDef.find(d => d.mappedCol === ImportFileHeader.year);
          if (col) {
            item.Year = row[col.header];
            this.addValidatedHeaderCol(ImportFileHeader.year, "Year", false);
            if (!item.Year || isNaN(item.Year))
              this.markInvalid([item],"year_class", "Year", isNaN(row[col.header]) ? "Not Number" : "Required", row[col.header] ? false : true );
          }
          col = this.dataColDef.find(d => d.mappedCol === ImportFileHeader.stage);
          if (col) {
            item.Stage = row[col.header];
            this.addValidatedHeaderCol(ImportFileHeader.stage, "Stage", false);   
            let stageExists = this.stages.includes(item.Stage);    
            if (item.Stage == null || !stageExists)
              this.markInvalid([item],"stage_class", "Stage", item.Stage == null ? "Required" : "Stage Not Found In " + this.selectedCrop.Name, item.Stage == null ? true : false, !stageExists);
          }
          col = this.dataColDef.find(d => d.mappedCol === ImportFileHeader.metaData);
          if (col) {
            item.MetaData = row[col.header];
            this.addValidatedHeaderCol(ImportFileHeader.metaData, "MetaData", false);
          }
          col = this.dataColDef.find(d => d.mappedCol === ImportFileHeader.customMetaData);
          if (col) {
            item.CustomMetaData = row[col.header];
            this.addValidatedHeaderCol(ImportFileHeader.customMetaData, "CustomMetaData", false);           
          }     
          col = this.dataColDef.find(d => d.mappedCol === ImportFileHeader.programComment); 
          if (col) {
            item.Comments = row[col.header];
            this.addValidatedHeaderCol(ImportFileHeader.programComment, "Comments", false);      
          }      
          this.dataToSubmit.push(item);
        });
        this.showImportGrid = this.openImportedData = false;
        this.displayGrid = true;
        this.loader.clearMessage();
      }
    });    
  }  

  exportTemplate() {
    this.commonService.generateExcel();
  }

  reset() {
    if (this.dataToSubmit.length > 0 && this.displayGrid) {
      this.confirmService.confirm({
        header : 'Confirm',
        message: 'All changes will be lost',
        accept: () => {        
          this.refreshPage();
        }
      });
    }    
  }

  refreshPage() {
    this.valDataColDef.splice(0);
    this.dataToSubmit.splice(0);
    this.displayGrid = false;
    this.importColumnDefs.splice(0);
    this.dataColDef.splice(0);
    this.showConfirmSheet = false;
    this.sheets.splice(0);
  }

  getMasterData(isGe? : boolean) {  
    const crop = isGe ? this.selectedGECrop.Name : this.selectedCrop.Name;
    StaticFields.masterData?.filter(p => p.type == 'Stage' && p.crop == crop && p.isActive).forEach((s: any) => {   
    if (s.stage && !this.stages.includes(s.stage)){      
      this.stages.push(s.stage);
    }
    if (s.nextStage && !this.stages.includes(s.nextStage)){
      this.stages.push(s.nextStage);
    }});
    if (isGe)
      this.getPrograms(true);
  } 

  getMetaData() {  
    StaticFields.metaData?.filter(p => (p.crop === "ALL" || p.crop ==this.selectedCrop.Name) && p.isActive).forEach((s: any) => {   
      if(s.name && !this.metaData.includes(s.name)){      
        this.metaData.push(s.name);
      }
    });     
  } 

  getPrograms(isGe: boolean) {  
    const cropId = isGe ? this.selectedGECrop.Id : this.selectedCrop.Id;
    this.apiService.getRADData(`Programs?$filter=CropId eq ${cropId} and Status/Name eq 'Active'&$select=Id,Code`, false).subscribe((result : any) => {
      if (isGe)
        this.gePrograms = result.value.sort((a: any , b: any) => a.Code.localeCompare(b.Code)).map((r: any) => r.Code);
      else 
        this.programs = result.value.sort((a: any , b: any) => a.Code.localeCompare(b.Code)).map((r: any) => r.Code);
    });   
  }

  submit() {
    const invalidValues = this.dataToSubmit.filter((ele : any) => {return ele.isInvalid === true});
    if (invalidValues.length > 0) {
      this.confirmService.confirm({
        header : 'Confirm',
        message: 'The imported data contains invalid values. Do you want to proceed with submitting valid values?',
        accept: () => {
          this.submitExcel();
        }
      });
    } else {
      this.submitExcel();
    }
  }

  submitExcel() {
    if (!this.submitDisabled){
      this.apiService.putData("SubmitImportFileData", this.dataToSubmit).subscribe((res: any) => {
        if (res.httpStatusCode == 200) {
          this.refreshPage();
        }
      });            
    }
  }

  openGeDialog() {
    this.openImportByGes = true;
  }

  fill(params: any, fillType: string) {
    if (fillType === "down" || fillType === "up") {
      const children = params.node.parent.childrenAfterGroup;
      let idx = children.findIndex((c: any) => c.id === params.node.id);
      children.forEach((rowNode: any) => {
        if (fillType === "up" ? idx > rowNode.childIndex : idx < rowNode.childIndex) {          
            rowNode.data[params.column.colId] = params.value;
          }
      });
    } else if (fillType === "selected") {
      params.api.getSelectedRows().forEach((rowNode: any) => {       
          rowNode[params.column.colId] = params.value;
        
      });
    }
    params.api.refreshCells(params);
  }

  getContextMenuItems(  params: GetContextMenuItemsParams,
  ): (string | MenuItemDef)[] {
    const result: (string | MenuItemDef)[] = [
      {
        name: 'Fill Up',
        disabled: !(params.value && params.node.rowIndex > 0),
        action: function () { params.context.componentParent.fill(params, "up"); },
      },
      {
        name: 'Fill Down',
        disabled: !(params.value && params.node.rowIndex < params.context.componentParent.selectedGeIdsData.length),
        action: function () { params.context.componentParent.fill(params, "down"); }
      },
      {
        name: 'Fill Selected',
        disabled: !(params.value && params.api.getSelectedRows().length > 1),
        action: function () { params.context.componentParent.fill(params, "selected"); }
      },
      'copy',
      'copyWithHeaders',
      'separator',
      'export'
    ]
    return result;
  }

  onGeCropChange() {    
    if(this.selectedGeIdsData.length > 0) {
      this.confirmService.confirm({
        header : 'Confirm',
        message: "You may lose all the changes made , are you sure you want to chage the crop without saving ?",
        accept: () => { 
          this.selectedGeIdsData = [];     
          this.selectedGeIdsDataCopy = [];
          this.getMasterData(true);
        }
      })
    } else {
      this.selectedGeIdsData = [];     
      this.selectedGeIdsDataCopy = [];
      this.getMasterData(true);
    }
    
  }

  onYearChange() {
    if(this.selectedGeIdsData.length > 0) {
      this.confirmService.confirm({
        header : 'Confirm',
        message: "You may lose all the changes made , are you sure you want to chage the crop without saving ?",
        accept: () => { 
          this.selectedGeIdsData = []; 
          this.selectedGeIdsDataCopy = [];    
        }
      })
    } else {
      this.selectedGeIdsData = []; 
      this.selectedGeIdsDataCopy = [];    
    }
  }

  resetGeImportScreen() {
    this.selectedGECrop = null;
    this.selectedYear = null;
    this.idList = [];
    this.selectedGeIdsData = [];
    this.selectedGeIdsDataCopy = [];
    this.invalidIds = [];
  }

  getDataByGes() {
    let gesToImport : any =[];
    let postData : any = {};
    postData['cropName'] = this.selectedGECrop.Name;
    if(this.searchByGeIdName == "GE NAME") {
      postData['geList'] = this.idList;  
      gesToImport = this.idList    
    } else {
      postData['geList'] = this.idList.map(id => Number(id));
      gesToImport = this.idList.map(id => Number(id));
    }
    postData['year'] = this.selectedYear;
    this.apiService.postData("GetProgramsListByGEs", postData, false,true).subscribe({
      next: (response: any) => {
        if (response.httpStatusCode == 200) {
          const data = this.selectedGeIdsData.splice(0);
          this.invalidIds = response.data[0].invalidGes.map((num : any) => num.toString());
          gesToImport.forEach((id:any) => {
            if (data.findIndex(ge => this.searchByGeIdName == "GE NAME" ? ge.geName === id : ge.geId === id) === -1) {
              const foundData = response.data[0].refinedResult.find((d: any) => this.searchByGeIdName == "GE NAME" ? d.geName === id : d.geId === id );
              if (foundData)
                data.push(foundData);
              // else if(!this.invalidIds.includes(id.toString()))
              //   data.push({geId: id,name:"",stage:"",comments:"",metadata:"",geName:"",customMetaData:""});
            }
          });
          this.selectedGeIdsData = [...data];
        }
        this.selectedGeIdsDataCopy = JSON.parse(JSON.stringify(this.selectedGeIdsData));
      }
    });
  }

  submitFetchedProgramsByGes() {
    let data : any = [];
    this.selectedGeIdsData.forEach(ele => {
      let item : any= new Object();
      item.GeId = ele.geId;
      item.Name = ele.name
      item.Year = this.selectedYear;
      item.Stage = ele.stage;
      item.MetaData = ele.metadata
      item.CustomMetaData = ele?.customMetaData ? ele?.customMetaData : "";
      item.Comments = ele.comments;
      item.Crop = this.selectedGECrop.Name;   
      data.push(item);
    })
    const validData = data.filter((ele : any) => { 
      if(!ele.Name || !ele.Stage )  {
        return false;
      } else if(ele.Name !== "" && ele.Stage !== "") {
        return true;
      } else {
        return false;
      }
    });
    if(data.length - validData.length > 0) {
      this.confirmService.confirm({
        header:'Confirm',
        message: `There are ${data.length - validData.length} invalid row(s) which have missing program or pstage data or even both. Do you want to submit valid rows?`,
        accept : () => {
          this.apiService.putData("SubmitImportFileData", validData, false, true).subscribe((res: any) => {
            if (res.httpStatusCode == 200) {
              this.openImportByGes = false;
              this.resetGeImportScreen();
              this.customMessageService.showMessage({severity:'success',summary: 'Data Submitted',detail:'Data for selected GE(s) has been submitted'})
            }
          }); 
        }
      })
    } else {
      this.apiService.putData("SubmitImportFileData", validData, false, true).subscribe((res: any) => {
        if (res.httpStatusCode == 200) {
          this.openImportByGes = false;
          this.resetGeImportScreen();
          this.customMessageService.showMessage({severity:'success',summary: 'Data Submitted',detail:'Data for selected GE(s) has been submitted'})
        }
      }); 
    }
    
  }

  setSearch($event: RadioButtonClickEvent) {
    if($event) {
     this.idList = []
    }
   }
}

function cellStyle(params: CellClassParams) {
    if(!params.data.name) {
      return {
        'border-style': 'solid',
        'border-color': 'red'
      } 
    } else {
      return {
        'border-style': 'none',
        'border-color': 'transparent'
      } 
    }
 
}

function stageCellStyle(params: CellClassParams) {
  if(!params.data.stage) {
    return {
      'border-style': 'solid',
      'border-color': 'red'
    } 
  } else {
    return {
      'border-style': 'none',
      'border-color': 'transparent'
    } 
  }

}