<p-confirmDialog appendTo="body" header="Confirmation" icon="pi pi-exclamation-triangle" [baseZIndex]="15000"></p-confirmDialog>
<p-panel header="Import File" toggleable="true">
    <ng-template pTemplate="icons">
      <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" (onClick)="menu.toggle($event)" >Add GE(S)</p-button>  
      <p-menu #menu [model]="items" [popup]="true">
        <ng-template pTemplate="item" let-item>
          <a pRipple class="flex align-items-center p-menuitem-link">
              <span [class]="item.icon"></span>
              <span class="ml-2">{{ item.label }}</span>          
          </a>
      </ng-template>
      </p-menu>
      <input id="importFile" type="file" style="display:none" (change)="handle($event)"
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
      <!-- <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top"  (click)="importFile('importFile')" [disabled]="!selectedCrop" class="buttonRst">Import
       
      </p-button>        -->
      <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" (click)="exportTemplate()" >Export Template</p-button>   
      <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!displayGrid" (click)="reset()" >Reset</p-button>
      <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top"  (click)="submit()" [disabled]="submitDisabled">Submit</p-button>
    </ng-template>
    <label for="crop" style="margin-top: 6px;">Crop*: </label>
    <p-dropdown [options]="crops" [style]="{'margin-left': '10px', 'width': '140px'}" placeholder="Select" [(ngModel)]="selectedCrop" (onChange)="refreshPage()" optionLabel="Name" inputId="crop"></p-dropdown>    
      <div>
        <ag-grid-angular *ngIf="displayGrid"
          style="height: 55vh; margin-left: -12px; margin-right: -11px; margin-bottom: -8px;" 
          class="ag-theme-balham"
          [rowData]="dataToSubmit" 
          [columnDefs]="valDataColDef"
          [defaultColDef]="defaultColDef" 
          [pagination]="true"
          rowSelection="multiple"
          [enableBrowserTooltips]="true"
          [paginationPageSize]="50"
          [gridOptions]="gridOptions">
        </ag-grid-angular>
        <p-dialog [contentStyle]="{'overflow':'visible'}"  [(visible)]="openImportedData" [modal]="true"
        [style]="{width: '1200px', height:'400px'}" [baseZIndex]="10000" [dismissableMask]="false" [closeOnEscape]="false" [draggable]="false" appendTo="body">
        <p-header class="grid" style="width: 1150px;">
            <label style="margin-top: 7px; font-size: 18px;">Map Headers </label>
            <label style="margin-right: 20px; font-size: 14px; margin-left: 10%;margin-top: 7px;"><b>Total Columns:</b>
              {{importColumnDefs.length}}</label>
            <label style="margin-right: 20px; font-size: 14px;margin-top: 7px;"><b>Total Mapped Columns:</b> {{mappedColCount}}</label>
            <label style="margin-right: 20px; font-size: 14px;margin-top: 7px;"><b>Total Unmapped Columns:</b>
              {{importColumnDefs.length - mappedColCount}}</label>
            <button pButton type="button"
              style="margin-left: 20px; margin-right: 20px; border-radius: 20px; width: 20px; height: 20px;top: 7px !important;" icon="pi pi-info"
              data-toggle="tooltip" data-placement="top" [title]="tooltipText"
              [style.backgroundColor]="this.tooltipText !== 'All required columns mapped.' ? 'red' : '#2399e5'"></button>
            <button pButton type="button" [disabled]="this.tooltipText !== 'All required columns mapped.'" style="margin-left: 130px; margin-right: 20px; min-width: 40px;top: 7px !important;"
            (click)="onValidate()">Ok</button>
            <button style="min-width: 60px;top: 7px !important;" pButton type="button" (click)="openImportedData = false">Cancel</button>
        </p-header>
        <p-table *ngIf="showImportGrid" [columns]="importColumnDefs" [value]="gridImportData" [scrollable]="true"
          scrollHeight="350px" class="minHeightGrid">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of importColumnDefs" style="min-width: 14.5em;width: 14.5em;">
                {{col.header}}
                <select [ngModel]="col.mappedCol"
                (change)="onHeaderSelectionChange($event.target, col)">
                <option *ngFor="let val of headerCols" [ngValue]="val">{{val}}</option>
              </select>
              </th>
            </tr>         
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>           
              <td data-toggle="tooltip" data-placement="top" title="{{rowData[col.header]}}" *ngFor="let col of columns"
                style="width: 14.5em;min-width: 14.5em;  white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                {{rowData[col.header]}}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-dialog>
        <p-dialog [(visible)]="showConfirmSheet" [draggable]="false" header="Select a Sheet"
        [style]="{width: '300px', height:'300px'}" [baseZIndex]="10000" [modal]="true" [dismissableMask]="false" appendTo="body">
        <p-listbox *ngIf="sheets && sheets.length > 0" [options]="sheets" (onChange)="onSheetChange($event)" 
          [style]="{'width':'15rem'}" optionLabel="name" [listStyle]="{'max-height': '200px'}"></p-listbox>
      </p-dialog>
      </div>
      <p-dialog [contentStyle]="{'overflow':'scroll'}" [(visible)]="openImportByGes" [modal]="true" (visibleChange)="resetGeImportScreen()"
        [style]="{width: '1650px', height:'700px'}" [baseZIndex]="10000" [dismissableMask]="false" [closeOnEscape]="false" [draggable]="false" appendTo="body">
        <ng-template pTemplate="header">
          <span style="width:100%"> 
          <div  style="display: flex;justify-content: space-between;">
            <div>
              <label style="font-weight: bold;font-size: 17px;">Import by GEID(s)</label>
            </div>
            <div>
              <button style="width: fit-content; " pButton type="button" [disabled]="!(selectedGECrop && selectedYear && idList.length > 0)" (click)="getDataByGes()">Add</button>
              <button style="width: fit-content; margin-left: 10px;" pButton type="button" [disabled]="!(selectedGECrop || selectedYear || idList.length > 0)" (click)="resetGeImportScreen()">Reset</button>
              <button style="width: fit-content; margin-left: 10px;" pButton type="button" [disabled]="!(selectedGeIdsData.length > 0 && saveEnabledForGes) " (click)="submitFetchedProgramsByGes()">Submit</button>
            </div>
          </div>      
          </span>
        </ng-template>
        <div class="row">
          <div class="col-3" style="margin-left: 10px;width: 22%;">
            <p-floatLabel style="margin-left: 20px;">
              <p-dropdown [options]="crops" [(ngModel)]="selectedGECrop" [filter]="true" optionLabel="Name" (onChange)="onGeCropChange()"
                placeholder="Select" />
              <label style="font-size: large;">Crop </label>
            </p-floatLabel>
            <p-floatLabel style="margin-left: 20px;">
              <p-dropdown [options]="years" [(ngModel)]="selectedYear" [filter]="true" placeholder="Select" (onChange)="onYearChange()"/>
              <label style="font-size: large;">Year </label>
            </p-floatLabel>
            <div style="display: block;margin-top: 15px;">
              <label style="font-weight: bold;font-size: large;vertical-align: middle;"> Search By:</label>
              <p-radioButton value="GE NAME" [(ngModel)]="searchByGeIdName" label="GE NAME" (onClick)="setSearch($event)" />
              <p-radioButton value="GE ID" label="GE ID" [style]="{'margin-left': '5px'}" [(ngModel)]="searchByGeIdName" (onClick)="setSearch($event)" />
              <label style="font-weight: bold; display: block; margin-bottom: 12px;margin-top: 5px;">Total Rows :
                  {{idList.length}}</label>
              <span>
                  <textarea style="max-height: 244px;" [rows]="10" [cols]="20" [autoResize]="false" [(ngModel)]="idListText" pInputTextarea></textarea>
              </span>
          </div>
              <label style="font-weight: bold; display: block; margin-bottom: 12px;margin-top: 9px;">Invalid GE(S)</label>
              <span>
                <textarea pInputTextarea style="max-height: 244px;" [rows]="10" [cols]="20" [(ngModel)]="invalidIdListText"></textarea>
              </span>

          </div>
          <div class="col-9" style="margin-left: 10px;margin-top: 15px;">

            <ag-grid-angular #geGrid *ngIf="selectedGeIdsData.length > 0"
            style="height: 55vh; margin-left: -12px; margin-right: -11px; margin-bottom: -8px;" 
            class="ag-theme-balham"
            [rowData]="selectedGeIdsData" 
            [columnDefs]="geIdColDef"          
            [enableRangeSelection]="true"
            [undoRedoCellEditing]="true"
            [getContextMenuItems]="getContextMenuItems"
            [defaultColDef]="defaultColDef" 
            rowSelection="multiple"
            [enableBrowserTooltips]="true"
            [gridOptions]="geGridOptions">
          </ag-grid-angular>
  </div>
        
        </div>
      </p-dialog>
</p-panel>