import { Component, OnInit, Renderer2, RendererFactory2, ViewChild, ViewContainerRef } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { CellEditingStartedEvent, CellEditingStoppedEvent, ColDef, GridOptions, GridReadyEvent, StatusPanelDef } from 'ag-grid-community';
import { ConfirmationService } from 'primeng/api';
import { Crop } from 'src/app/models/crop';
import { ApiService } from 'src/app/services/api.service';
import { CropUpdateService } from 'src/app/services/cropUpdate.service';
import { ManageCropModalHeaderComponent } from 'src/app/shared/cropManageHeader';
import { ReportModalHeaderComponent } from 'src/app/shared/reportModalHeader';
import { StaticFields } from 'src/app/shared/staticFields';

@Component({
  selector: 'app-managecrop',
  templateUrl: './managecrop.component.html',
  styleUrl: './managecrop.component.scss'
})
export class ManagecropComponent implements OnInit {
  rowData : any[] =[];
  idList: string[] = [];
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };
  public columnDefs: ColDef[] = [
    { field: 'Name', headerName: 'Crop', minWidth: 50},
    { field: 'Description', headerName: 'Description',minWidth: 50},
    { field: 'MaterialType', headerName: 'Type', cellEditor: 'agRichSelectCellEditor' , editable: true,
      cellEditorSelector: params => {
        return {
          component: 'agRichSelectCellEditor',
          params: {
            values: StaticFields.materialTypes.sort(),
            searchType: 'matchAny',
            filterList: true,
            highlightMatch: true,
            multiSelect: true
          }
        }
      }
    },
    { field: 'isActive', headerName: 'Active', cellEditor: 'agCheckboxCellEditor', editable: true,minWidth: 30}
  ];
  public statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      { statusPanel: 'agTotalRowCountComponent', align: 'left' },
      { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left',  },
      { statusPanel: 'agFilteredRowCountComponent', align : 'left' },
      { statusPanel: 'agSelectedRowCountComponent', align : 'left' },
     
    ],
  };
  gridOptions : GridOptions = {
    context: {
      componentParent: this,
    },
    singleClickEdit: true,
    tooltipShowDelay: 100,
    
    sideBar: { toolPanels: [{
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivotMode: true,
      },
    },
    'filters'] },
    
    // onGridPreDestroyed: this.onGridPreDestroyed
  }
  gridApi: any;
  renderer: Renderer2;

  get allowReset() : boolean {
    const managedRadCrops = this.rowData.map(ele => {return {Name :ele.Name,Description :  ele.Description, isActive: ele.isActive, MaterialType : ele?.MaterialType ? ele?.MaterialType.sort().join() : ""}}).sort((a: any , b: any) => a.Name.localeCompare(b.Name));
    const allRadCrops = StaticFields?.crops?.map(ele => {return {Name :ele.Name,Description :  ele.Description, isActive: ele.isActive,  MaterialType : ele?.MaterialType ? ele?.MaterialType.split(",").sort().join() : ""}}).sort((a: any , b: any) => a.Name.localeCompare(b.Name));
    return JSON.stringify(managedRadCrops) == JSON.stringify(allRadCrops);
  }
  get crops(): Crop [] {
    return StaticFields?.crops && StaticFields?.crops?.length > 0 ? StaticFields?.crops : [];
  }

  constructor(private apiService : ApiService,  private confirmationService : ConfirmationService, private cropUpdateService : CropUpdateService,
    private readonly rendererFactory: RendererFactory2,
    private viewContainerRef: ViewContainerRef) {
    this.renderer = rendererFactory.createRenderer(null,null);
  }
  
  ngOnInit(): void {
    if(StaticFields?.crops && StaticFields?.crops?.length > 0 ) {
    //  StaticFields?.crops?.forEach(ele => ele.isActive = false);`
     this.rowData = JSON.parse(JSON.stringify(StaticFields?.crops));
     this.rowData.forEach(ele => {
      if( ele?.MaterialType && ele?.MaterialType !== "" ) {
        ele.MaterialType = ele.MaterialType?.split(",");
      } else {
        ele.MaterialType = [];
      }
     })
     this.setScreenData();
    }
  }

  setScreenData() {
    setTimeout(() => {
      const componentRef = this.viewContainerRef.createComponent(ManageCropModalHeaderComponent);
      componentRef.instance.incomingData = Object.create(this);
      let titleSpan = document.getElementsByClassName('p-dialog-header-icons')[0];
      this.renderer.insertBefore(titleSpan, componentRef.location.nativeElement,titleSpan.childNodes[0]);
    },300);
  }

  onCellEditingStarted(event: CellEditingStartedEvent) {
    if(event.data.Name === StaticFields.precodePreference.crop && event.value == true) {
      this.confirmationService.confirm({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to make the crop inactive, it is saved as default preference ?',
        accept: () => {           
        },
        reject: () => {
          event.data.isActive = true;
          this.gridApi.redrawRows()
        }
        
      })
    }
  }

  onCellEditingStopped(event: CellEditingStoppedEvent) {
  }

  onGridReady($event: GridReadyEvent<any>) {
    this.gridApi = $event.api;
  }
  
  submitData() {
    const activeCrops = JSON.parse(JSON.stringify(this.rowData.filter(ele => ele.isActive)));
    const activeCropsWoType = activeCrops.filter((ele: any) => ele?.MaterialType?.join() === "");   
    if (activeCropsWoType.length > 0) {
      this.confirmationService.confirm({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: `Active Crops must have atleast 1 Material Type. ${activeCropsWoType.length} crop(s) have it blank. Do you want to save remaining data?`,      
        accept: () => {          
          let validData = activeCrops.filter((ele: any) => ele.MaterialType.join() != "");
          validData.forEach((ele: any) => {ele.MaterialType = ele.MaterialType.join();});
          this.submitCropPreference(validData);
        }
      });
    } else {
      let validData = JSON.parse(JSON.stringify(this.rowData));
      validData.forEach((ele: any) => ele.MaterialType = ele?.MaterialType?.join());
      this.submitCropPreference(validData);
    }
    
  }

  submitCropPreference(validData : any) {
    this.apiService.putData( "SubmitCropPreference", validData).subscribe((result : any) => {
      if (result.httpStatusCode == 200) { 
        this.rowData.forEach((ele : any) => {
          if (ele.isActive && ele.MaterialType.length ==  0) {
            ele.isActive = false;
          }
          const index = StaticFields.crops.findIndex(e => e.Name == ele.Name);
          if (index > -1){
            StaticFields.crops[index].isActive = ele.isActive;
            StaticFields.crops[index].MaterialType = ele.MaterialType.join();
            if (StaticFields.precodePreference.crop == ele.Name && !ele.isActive)
              this.cropUpdateService.resetDataCommunicate("true");
          }
        });
        this.gridApi.redrawRows();
      }
    });
  }

  reset() {
    this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      message: 'Are you sure you want to reset? Any unsaved changes will be lost',
      accept: () => {
        this.rowData = JSON.parse(JSON.stringify(StaticFields?.crops));
      }
      
    })
  }
}
